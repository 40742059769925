<template>
  <BasicModal
    :action="submitForm"
    :close-on-action="false"
    action-title="Continue"
    :show-action-button="!form.isSubmitted"
    :show-cancel-button="!form.isSubmitted"
    :disable-action-button="form.isLoading || !isFormValid"
  >
    <template v-if="!form.isSubmitted">
      <div class="header">
        <LightbulbIcon />
        <h5>Want faster access to your NFT media?</h5>
      </div>
      <p class="mb-6">
        We’re working on a caching system to host and optimize NFT media files
        for faster delivery to your apps and dApps via a simple API.
      </p>
      <FormSubmitErrors :formName="formName" />
      <FormRadio :formName="formName" fieldName="nftMediaCaching" ref="focusElement" />
      <LoadingOverlay v-if="form.isLoading" />
    </template>
    <template v-else>
      <div class="success-container">
        <img src="@/assets/images/planet.png" />
        <template v-if="form.fields.nftMediaCaching.value === 'Yes'">
          <h4>Great!</h4>
          <p>We’ll send you an email when NFT media caching is available.</p>
        </template>
        <template v-else>
          <h4>Thanks!</h4>
          <p>Your feedback helps us improve Dispatch and make it more useful for you.</p>
        </template>
      </div>
    </template>
  </BasicModal>
</template>

<script>

  import useForm from '@/composables/useForm'
  import useRecaptcha from '@/composables/useRecaptcha'

  import LightbulbIcon from '@/assets/icons/lightbulb.svg'

  import FormRadio from '@/components/forms/FormRadio.vue'
  import BasicModal from '@/components/modals/_BasicModal.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  export default {
    components: {
      FormRadio,
      BasicModal,
      LightbulbIcon,
      LoadingOverlay,
      FormSubmitErrors,
    },
    setup() {

      // data
      const formName = 'nftMediaCachingForm'

      // composables
      const { form, isFormValid, focusElement } = useForm({ formName })
      const { setRecaptchaToken } = useRecaptcha({ formName, action: 'nft_media_caching' })

      return {
        form,
        formName,
        isFormValid,
        focusElement,
        setRecaptchaToken,
      }

    },
    methods: {
      submitForm() {
        return this.setRecaptchaToken()
          .then(() => {
            return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
              .then(() => {
                return this.$store.dispatch('user/UPDATE_USER_FLAGS', { hasAnsweredNftMediaCaching: true })
              })
              .catch(() => {
                // do nothing, template will show error
              })
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .header
    @apply mb-4

    @apply flex
    @apply items-center

    h5
      @apply mb-0
      @apply ml-1

    svg
      @apply w-8
      @apply h-8
      @apply text-neutral-500

  .success-container
    @apply flex
    @apply flex-col
    @apply items-center

</style>
