import { useStore } from 'vuex'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default (props) => {

  // composables
  const store = useStore()
  const { recaptchaLoaded, executeRecaptcha } = useReCaptcha()

  // methods
  const setRecaptchaToken = () => {

    if (!process.env.VUE_APP_RECAPTCHA_SITE_KEY) {
      return Promise.resolve()
    }

    return recaptchaLoaded()
      .then(() => {

        return executeRecaptcha(props.action)
          .catch((error) => {

            store.dispatch('toast/CREATE_TOAST', {
              text: 'An error occurred, please try again later!',
              type: 'error',
            })

            throw error

          })
          .then((recaptchaToken) => {

            store.commit('forms/SET_FIELD_VALUE', {
              fieldName: 'recaptchaToken',
              formName: props.formName,
              newValue: recaptchaToken,
            })

            return recaptchaToken

          })

      })

  }

  return {
    setRecaptchaToken,
  }

}
